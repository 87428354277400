import { QueryClientProvider } from '@tanstack/react-query';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { TrpcBase, queryClient, trpcConfig } from './trpc';

export default function TRPCLayout({
	children,
	app,
	isUseAuth = true,
}: {
	children: ReactNode;
	app: string;
	isUseAuth?: boolean;
}) {
	const client = useMemo(() => {
		return TrpcBase.createClient(trpcConfig(app, isUseAuth));
	}, [app, isUseAuth]);

	const base = useMemo(() => {
		return (
			<TrpcBase.Provider client={client} queryClient={queryClient}>
				<QueryClientProvider client={queryClient}>
					{children}
				</QueryClientProvider>
			</TrpcBase.Provider>
		);
	}, [client, children]);

	if (!isUseAuth) {
		return base;
	}

	return base;
}
